import { SubmissionError } from 'redux-form';
import  checkUserEmail  from '../../../actions/shop/login/checkUserEmail';
import  userLogin  from '../../../actions/shop/login/userLogin';
import history from '../../../history';
import { loadAccount } from '../../../actions/shop/userAccount/userAccount';

async function submit(values, dispatch) {
    
    let response = {};
    if(values.page == '2') {
        response = await dispatch(userLogin(values.email, values.password));
        if(response.status == 200 ) {
            dispatch(loadAccount());
            history.push('/restaurant/dashboard');
        }
    } else {
        response = await dispatch(checkUserEmail(values));
        
    }
    
    if( response.status == 400){
        throw new SubmissionError({_error: response.errorMessage});
    } 
}

export default submit;