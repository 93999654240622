
import React from 'react';
import AddFoodItem from './AddFoodItem';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, intl, params }) {
  const title = intl.formatMessage(messages.itemSettings);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/restaurant' };
  };

  const menuId = Number(params.menuId);
  const subMenuId = Number(params.subMenuId);

  return {
    title,
    component: (
      <DashboardLayout>
        <AddFoodItem title={title} menuId={menuId} subMenuId={subMenuId} />
      </DashboardLayout>
    ),
  };
}

export default action;
