
import React from 'react';
import EditMenu from './EditMenu';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.menu);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
  
  if (!isRestaurantAuthenticated) {
    return { redirect: '/restaurant' };
  };
  const id = Number(params.id);
  return {
    title,
    component: (
      <DashboardLayout>
        <EditMenu title={title} id={id} />
      </DashboardLayout>
    ),
  };
}

export default action;
