
import React from 'react';
import Item from './Item';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';


function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.itemSettings);
  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/restaurant' };
  };

  let menuId = Number(params.id);

  return {
    title,
    component: (
      <DashboardLayout>
        <Item title={title} menuId={menuId} />
      </DashboardLayout>
    ),
  };
}

export default action;
