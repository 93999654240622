import React from 'react';
import ChangePassword from './ChangePassword';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, intl, params }) {
  const title = intl.formatMessage(messages.changePassword);
    
    //from Redux Store
    let isAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
    
    if (!isAuthenticated) {
        return {
            redirect: '/restaurant'
        }
    }

    return {
        title,
        component: <DashboardLayout><ChangePassword title={title} /></DashboardLayout>
    }
}

export default action;