import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Switch from 'react-switch';
import cx from 'classnames';

export const uncheckedIcon = (
    <svg viewBox="0 0 52 52" fill="#000000" fillOpacity="0"
        stroke="#000000" strokeWidth="4"
        role="presentation" strokeLinecap="round" strokeLinejoin="round"
        style={{ position: "absolute", top: 1, height: '33px', width: '33px', display: 'block', transform: 'scale(1.5)' }}>
        <path d="m19.1 19.1 l14 14 m 0 -14 l -14 14"></path>
    </svg>
);

export const checkedIcon = (
    <svg viewBox="0 0 52 52" fill="#000000" fillOpacity="0"
        stroke="#000000" strokeWidth="4"
        role="presentation" strokeLinecap="round" strokeLinejoin="round"
        style={{ position: "absolute", top: 1, height: '33px', width: '33px', display: 'block', transform: 'scale(1.5)' }}>
        <path d="m19.1 25.2 4.7 6.2 12.1-11.2"></path>
    </svg>
);
class SwitchButton extends React.Component {
    static propTypes = {
        change: PropTypes.any
    }

    static defaultProps = {
        checked: false,
        checkedValue: true,
        unCheckedValue: false,
        offColor: '#dce0e0',
        onColor: '#5EBE00',
        checkedIcon: checkedIcon,
        uncheckedIcon: uncheckedIcon,
        height: 34,
        width: 58,
        boxShadow: 'none',
        activeBoxShadow: '0px 0px 2px 3px #5EBE00',
        isPersonalize: false
    };

    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { defaultValue, value } = this.props;
        this.setState({
            checked: value
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { defaultValue } = nextProps;

        this.setState({
            checked: defaultValue
        });

    }

    async handleChange(e) {
        const { change, index } = this.props;
        this.setState({
            checked: e
        });
        await change('OperationHours', `OperationHours[${index}].isOpen`, e);
        if (e) {
            await change('OperationHours', `OperationHours[${index}].isAllHours`, '1');
        } else {
            await change('OperationHours', `OperationHours[${index}].isAllHours`, '0');
            await change('OperationHours', `OperationHours[${index}].startTime`, null);
            await change('OperationHours', `OperationHours[${index}].endTime`, null);

        }
    }

    render() {
        const { switchOnLabel, switchOffLabel } = this.props;
        const { offColor, onColor, checkedIcon, uncheckedIcon, height, width, boxShadow, index } = this.props;
        const { checked } = this.state;

        return (
            <div>
                <Switch
                    id={`open-type-${index}`}
                    checked={checked}
                    onChange={this.handleChange}
                    offColor={offColor}
                    onColor={onColor}
                    handleDiameter={30}
                    checkedIcon={checkedIcon}
                    uncheckedIcon={uncheckedIcon}
                    height={height}
                    width={width}
                    boxShadow={boxShadow}
                    className={'siwtchLapel'}
                />
                <label className={cx('siwtchLapelText', 'siwtchLapelTextRTL')}>{checked ? switchOnLabel : switchOffLabel}</label>
            </div>
        )
    }
}

const mapState = (state) => ({});

const mapDispatch = {
    change
};

export default connect(mapState, mapDispatch)(SwitchButton);