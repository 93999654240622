exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._109DH {\n    margin: 0 0 0 auto;\n}\n._1sfOP {\n    display: grid;\n    grid-template-columns: repeat(2,1fr);\n}\n.QDKcI {\n    position: relative;\n    top: -15px;\n    height: 25px; \n    display: inline-block;\n    min-width: 25px;\n    padding: 0 3px;\n    border-radius: 50%;\n    font-weight: 700;\n    font-size: 11px;\n    line-height: 24px;\n    color: #fff;\n    border: 1px solid #5EBE00;\n    background: #5EBE00;\n}\n@media screen and (max-width: 1200px) {\n    ._1sfOP {\n        grid-template-columns: repeat(1,1fr);\n    }\n    ._109DH {\n        margin: 0;\n    }\n}", ""]);

// exports
exports.locals = {
	"searchInput": "_109DH",
	"titleSection": "_1sfOP",
	"badge": "QDKcI"
};