import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './FailedPayoutContainer.css';
import rs from '../../../../components/restaurantCommon.css';
import history from '../../../../history';
import messages from '../../../../locale/messages';
import { verifyPayout } from '../../../../actions/shop/payout/verifyPayout';

class FailedPayoutContainer extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick() {
    history.push('/restaurant/addpayout');
  };

  render() {
    const { currentAccountId, verifyPayout } = this.props;

    return (
      <div className={'mainContainer'}>
        <Container fluid>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={12} lg={12} className={rs.alignCenter}>
              <div className={s.space4}>
                <h1 className={cx(s.textJumbo, 'hidden-xs', 'hidden-sm')}>
                  <FormattedMessage {...messages.payoutFailure} />
                </h1>
                <h2 className={s.subText}><FormattedMessage {...messages.payoutFailureSubtitle} /></h2>
                <p className={s.marginTop20}><FormattedMessage {...messages.payoutFailureContent} /></p>
              </div>
              <Button
                className={cx(rs.btn, rs.btnPrimaryBorder, s.firstBtn, rs.space3, s.bntWidth)}
                onClick={() => verifyPayout(currentAccountId)}
              >
                <FormattedMessage {...messages.payoutRetry} />
              </Button>
              <Button
                className={cx(rs.btn, rs.btnPrimary, rs.space3, s.bntWidth)}
                onClick={this.handleClick}
              >
                <FormattedMessage {...messages.addPayout} />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

const mapState = (state) => ({

});

const mapDispatch = {
  verifyPayout
};

export default withStyles(s, rs)(connect(mapState, mapDispatch)(FailedPayoutContainer));