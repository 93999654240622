
import React from 'react';
import DashboardContainer from './DashboardContainer';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.dashboard);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/restaurant' };
  };

  return {
    title,
    component: (
      <DashboardLayout>
        <DashboardContainer title={title} />
      </DashboardLayout>
    ),
  };
}

export default action;
